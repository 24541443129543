import React from 'react'
import "./SubHeadlineStyle.css"
import SquareFootIcon from '@mui/icons-material/SquareFoot';
const InfiniteText = (props) => {
  return (<div className='infinite'>
  
    {props.english ?
    <>
    <div className={props.direction==="left"?"infinite-text-left":"infinite-text-right"}>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>

    </div>
    <div className={props.direction==="left"?"infinite-text-left":"infinite-text-right"} >
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span>EOSSC <SquareFootIcon className='triangle'></SquareFootIcon></span>

    </div>
    </>
    :
    <>
    <div className={props.direction==="left"?"infinite-text-left":"infinite-text-right"}>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>

    </div>
    <div className={props.direction==="left"?"infinite-text-left":"infinite-text-right"} >
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>
        <span> المكتب المصري <SquareFootIcon className='triangle'></SquareFootIcon></span>

    </div>
    </>

    }
    </div>
  )
}

export default InfiniteText
